import React from "react";

export default function Sketch() {
    let [items, setItems] = React.useState([]);
    const serverUrl = 'https://python.vishnusayanth.com';
    React.useEffect(() => {
        fetch(`${serverUrl}/sketch`, {
            method: 'GET',
        }).then(res => res.json()).then(res => setItems(res.data))
            .then(() => document.getElementById('spinner').style.display = 'none')
    }, [serverUrl]);
    return <React.Fragment>
        {items.map(item => {
            if (!item.includes('thumb')) {
                item = item.replace('\\', '/');
                const fileName = item.split('/')[1];
                const url = serverUrl + '/static/sketches/';
                const imageUrl = url + fileName;
                const thumbUrl = url + 'thumbs/' + fileName;
                return <React.Fragment key={item}>
                    <div className={"col-sm-1 col-sm-1 zoom "} align="center" style={{ 'width': '140px', 'height': '110px' }}>
                        <div className="overflow-hidden container" align="center">
                            <img className="img-fluid rounded" src={thumbUrl}
                                data-toggle="modal" data-target={`#modal-${fileName}`} align="center" alt="Sketch" style={{ "cursor": "pointer", height: '100px', 'width': '100px' }} />
                        </div>
                    </div>
                    <div className="modal fade" id={`modal-${fileName}`} role="dialog" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <img src={imageUrl} alt="sketch" className="img-fluid" />
                                </div>

                            </div>
                        </div>
                    </div>
                </React.Fragment>
            } else {
                return '';
            }
        })}
    </React.Fragment>
}